import { GvGSummary } from "../models/GvGSummary"

import React from 'react';
import 'react-select2-wrapper/css/select2.css';
import './Home.css';

type MyProps = {
    GvGSummary: GvGSummary;
};

// Renders the Player vs Player Summary model
export class GvGSummaryComponent extends React.Component<MyProps> {

    // Decide if this component should be displayed
    showComponent(): boolean {
        
        if (this.props.GvGSummary.Games === null || this.props.GvGSummary.Games.length === 0) {
            return false;
        }

        if (this.props.GvGSummary.Player1.isGoalie() && this.props.GvGSummary.Player2.isGoalie()) {
            return true;
        }

        return false;
    }

    // This might be useful for GvG
    // getGoaliePoints(): React.ReactNode {

    //     if (this.props.GvPSummary.PS1.Goals > 0 || this.props.GvPSummary.PS1.Assists)
    //     {
    //         return (
    //             <div>{this.props.GvPSummary.PS1.GetGoalsAssists} &larr; Goals-Assists</div>
    //         );
    //     }

    //     return (<div/>);    
    // }

    render(): React.ReactNode {

        if (!this.showComponent())
        {
             return (<div/>);    
        }
        
        return (
            <div>
                <div className="section-label2">As Opponents</div>
                <div className="section-label3"> 
                    <div>{this.props.GvGSummary.PS1.GetRecord()} &larr; Wins-Losses-Ties &rarr; {this.props.GvGSummary.PS2.GetRecord()}</div>
                    <div>{this.props.GvGSummary.PS1.GoalsAgainst} &larr; Goals Against &rarr; {this.props.GvGSummary.PS2.GoalsAgainst}</div>
                    <div>{this.props.GvGSummary.PS1.Saves} &larr; Saves &rarr; {this.props.GvGSummary.PS2.Saves}</div>
                    <div>{this.props.GvGSummary.PS1.GetSavePercentage()} &larr; Save Percentage &rarr; {this.props.GvGSummary.PS2.GetSavePercentage()}</div>
                    <br/>
                </div>
            </div>
        );
    }
}