import { PvPSummary } from "../models/PvPSummary"

import React from 'react';
import 'react-select2-wrapper/css/select2.css';
import './Home.css';

type MyProps = {
    PvPSummary: PvPSummary;
};

type MyState = {
    foo: number;
};

// Renders the Player vs Player Summary model
export class PvPSummaryComponent extends React.Component<MyProps, MyState> {

    // Decide if this component should be displayed
    showComponent(): boolean {
        
        if (this.props.PvPSummary.Games === null || this.props.PvPSummary.Games.length === 0) {
            return false;
        }

        if (this.props.PvPSummary.Player1.isGoalie() || this.props.PvPSummary.Player2.isGoalie()) {
            return false;
        }

        return true;
    }

    render(): React.ReactNode {

        if (!this.showComponent())
        {
             return (<div/>);    
        }
        
        return (
            <div>
                <div className="section-label2">As Opponents</div>
                <div className="section-label3"> 
                    <div>{this.props.PvPSummary.PS1.GetRecord()} &larr; Wins-Losses-Ties &rarr; {this.props.PvPSummary.PS2.GetRecord()}</div>
                    <div>{this.props.PvPSummary.PS1.GetGoalsAssists()} &larr; Goals-Assists in Games Against &rarr; {this.props.PvPSummary.PS2.GetGoalsAssists()}</div>
                    <div>{this.props.PvPSummary.PS1.Fights} &larr; Fights &rarr; {this.props.PvPSummary.PS2.Fights}</div>
                    <div>{this.props.PvPSummary.PS1.GetPenaltiesPIMS()} &larr; Penalties-PIMS &rarr; {this.props.PvPSummary.PS2.GetPenaltiesPIMS()}</div>
                    <div>{this.props.PvPSummary.PS1.Hits} &larr; Hits &rarr; {this.props.PvPSummary.PS2.Hits}</div>
                    <div>{this.props.PvPSummary.PS1.Faceoffs} &larr; Faceoffs &rarr; {this.props.PvPSummary.PS2.Faceoffs}</div>
                    <div>{this.props.PvPSummary.PS1.BlockedShots} &larr; Blocked Shots &rarr; {this.props.PvPSummary.PS2.BlockedShots}</div>
                    <br/>
                </div>
            </div>
        );
    }
}