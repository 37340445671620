import { GvPSummary } from "../models/GvPSummary"

import React from 'react';
import 'react-select2-wrapper/css/select2.css';
import './Home.css';

type MyProps = {
    GvPSummary: GvPSummary;
};

// Renders the Goalie vs Player Summary model
export class GvPSummaryComponent extends React.Component<MyProps> {

    // Decide if this component should be displayed
    showComponent(): boolean {
    
        if (this.props.GvPSummary.Games === null || this.props.GvPSummary.Games.length === 0) {
            return false;
        }

        // // TODO: why do we need this?
        // if (typeof this.props.GvPSummary.Player1 === 'undefined' || typeof this.props.GvPSummary.Player2 === 'undefined')
        // {
        //     return false;
        // }

        if ((this.props.GvPSummary.Player1.isSkater() && this.props.GvPSummary.Player2.isGoalie())
            || (this.props.GvPSummary.Player1.isGoalie() && this.props.GvPSummary.Player2.isSkater()))
        {
            return true;
        }

        return false;
    }


    showGPSpecific(): React.ReactNode {

        if (this.props.GvPSummary.Player1.isSkater()) {
            // P1 is skater, P2 is goalie
            return (
            <div>
                <div>Saves-Shots Against &rarr; {this.props.GvPSummary.PS2.Saves}-{this.props.GvPSummary.PS1.Shots+this.props.GvPSummary.PS1.Goals}</div>
                <div>{this.props.GvPSummary.PS1.GetGoalsAssists()} &larr; Goals-Assists</div>
            </div>
            );
        }
        else {
            // P1 is goalie, P2 is skater
            return (
                <div>
                    <div>{this.props.GvPSummary.PS1.Saves}-{this.props.GvPSummary.PS2.Shots+this.props.GvPSummary.PS2.Goals} &larr; Saves-Shots Against</div>
                    <div>Goals-Assists &rarr; {this.props.GvPSummary.PS2.GetGoalsAssists()}</div>
                </div>
                );
        }
    }

    render(): React.ReactNode {
        
        if (!this.showComponent())
        {
             return (<div/>);    
        }

        return (
            <div>
                <div className="section-label2">As Opponents</div>
                <div className="section-label3"> 
                    <div>{this.props.GvPSummary.PS1.GetRecord()} &larr; Wins-Losses-Ties &rarr; {this.props.GvPSummary.PS2.GetRecord()}</div>
                    {this.showGPSpecific()}
                    <div>{this.props.GvPSummary.PS1.GetPenaltiesPIMS()} &larr; Penalties-PIMS &rarr; {this.props.GvPSummary.PS2.GetPenaltiesPIMS()}</div>
                    {/* <div>{this.props.GvPSummary.PS1.Fights} &larr; Fights &rarr; {this.props.GvPSummary.PS2.Fights}</div> */}
                    <br/>
                </div>
            </div>
        );
    }
}