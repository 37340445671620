import { Game } from "./Game";
import { PlayerSummary } from "./PlayerSummary"
import { Player } from "./Player"

// Goalie vs Goalie Summary model
export class GvGSummary {

    Player1: Player;
    Player2: Player;

    PS1: PlayerSummary = new PlayerSummary();
    PS2: PlayerSummary = new PlayerSummary();

    Games: Game[] = [];

    constructor(player1: Player, player2: Player, games: Game[]) {

        this.Player1 = player1;
        this.Player2 = player2;

        if (games === null || games.length === 0) {
            return;
        }

        // Regular season and playoff games
        var realGames = games.filter(g => g.type === "R" || g.type === "P").filter(g => !g.teammates);
        this.Games = realGames;
        var interactions = realGames.flatMap(g => g.interactions);

        var ps1: PlayerSummary = new PlayerSummary();
        ps1.GamesWon = realGames.filter(g => g.player1_Result === "W").length;
        ps1.GamesLost = realGames.filter(g => g.player1_Result === "L").length;
        ps1.GamesTied = realGames.filter(g => g.player1_Result === "T").length;
        ps1.Saves = realGames.reduce((sum, current) => sum + current.player1_Saves, 0);
        ps1.GoalsAgainst = realGames.reduce((sum, current) => sum + current.player1_ShotsAgainst, 0) - ps1.Saves;

        var ps2: PlayerSummary = new PlayerSummary();
        ps2.GamesWon = realGames.filter(g => g.player2_Result === "W").length;
        ps2.GamesLost = realGames.filter(g => g.player2_Result === "L").length;
        ps2.GamesTied = realGames.filter(g => g.player2_Result === "T").length;
        ps2.Saves = realGames.reduce((sum, current) => sum + current.player2_Saves, 0);
        ps2.GoalsAgainst = realGames.reduce((sum, current) => sum + current.player2_ShotsAgainst, 0) - ps2.Saves;

        this.PS1 = ps1;
        this.PS2 = ps2;
    }
}