import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import { HomeComponent } from './components/HomeComponent';

const App = () => {
  return (
    <Router>
      <div className="app">
          <div className="header">
            <div className="email">
              {/* This is here to keep symmetry with the div on the right side */}
            </div>
            <div className="title">NHL PLAYER INTERACTION VIEWER</div>
            <div className="email">
              <a className="emailLink" href = "mailto:team@seahac.com?subject=NHL Player Interaction Viewer">Contact</a>
            </div>
          </div>
          <div className="body">
            <Route path="/" component={HomeComponent} />
          </div>
      </div>
    </Router>
  );
}

export default App;
