import { Player } from "./Player";

export class PlayerSummary {

    // Game Totals
    GamesWon: number = 0;
    GamesLost: number = 0;
    GamesTied: number = 0;
    Goals: number = 0;
    Assists: number = 0;
    GoalsAgainst: number = 0;


    // Interaction Totals
    Penalties: number = 0; // Include fights
    PIMS: number = 0; // Include fights
    Fights: number = 0;
    Hits: number = 0;
    Faceoffs: number = 0;
    BlockedShots: number = 0;
    Shots: number = 0;
    Saves: number = 0;

    GoalsAssistedByTeammate: number = 0;
    GoalsAssistedOn: number = 0;
    BothAssistedOn: number = 0;

    GetRecord() {
        return `${this.GamesWon}-${this.GamesLost}-${this.GamesTied}`;
    }

    GetPoints() {
        return `${this.Goals}-${this.Assists}-${this.Goals+this.Assists}`;
    }

    GetGoalsAssists() {
        return `${this.Goals}-${this.Assists}`;
    }

    GetPenaltiesPIMS() {
        return `${this.Penalties}-${this.PIMS}`;
    }

    GetSavePercentage() {
        var savePercentage = this.Saves / (this.GoalsAgainst + this.Saves);
        return `${savePercentage.toFixed(3)}`;
    }

}