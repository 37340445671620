// use this to test locally: chrome.exe --user-data-dir="C:\test\chrome_session" --disable-web-security
import React from 'react';
import AutoSuggest from 'react-autosuggest';
import Button from 'react-bootstrap/Button';
import queryString from 'query-string';
import 'react-select2-wrapper/css/select2.css';

import './Home.css';
import { Game } from '../models/Game';
import { Interaction } from '../models/Interaction';
import { Player } from '../models/Player';
import { Summary } from '../models/Summary';

import { PwPSummaryComponent } from './PwPSummaryComponent';
import { PvPSummaryComponent } from './PvPSummaryComponent';
import { GvPSummaryComponent } from './GvPSummaryComponent';
import { GwPSummaryComponent } from './GwPSummaryComponent';
import { GvGSummaryComponent } from './GvGSummaryComponent';

import 'moment-timezone'; 
let moment = require('moment');

export interface StateProps {
}

export interface PageState {
    games: Game[];
    interactions: Interaction[];
    players: Player[];
    playerId1: string;
    playerId2: string;
    loading: boolean;
    hasSearched: boolean;

    summary: Summary;

    // AutoSuggest
    playerName1: any
    playerName2: any
    suggestions: any
}

 
export class HomeComponent extends React.Component<StateProps, PageState> {
    constructor(props: StateProps) {
        super(props);

        this.state = {
            games: [],
            interactions: [],
            players: [],
            playerId1: "",
            playerId2: "",
            loading: true,
            hasSearched: false,

            summary: new Summary(new Player(), new Player(), [] as any),

            // AutoSuggest
            playerName1: '',
            playerName2: '',
            suggestions: []
        }
    }

    componentDidMount() {
        let player1: string = "";
        let player2: string = ""
        var queryParams = queryString.parse(window.location.search);
        if (queryParams) {
            player1 = queryParams.p1 ? queryParams.p1.toString() : "";
            player2 = queryParams.p2 ? queryParams.p2.toString() : "";
    
            this.setState({
                playerId1: player1,
                playerId2: player2,
            });
        }

        this.getPlayers();

        if (player1 && player2) {
            this.getInteractions(player1, player2);
        }
    }

    apimh = "Ocp-Apim-Subscription-Key";
    apimk = "8d1d8cbf6fef433595ef50bb536f31bd";

    getPlayers() {
        const apiEndpoint = "https://goboomboom.azure-api.net/v1/players";

        var xhr = new XMLHttpRequest()
        xhr.addEventListener('load', () => {
            console.log("Loading all players ...");
            var playaz = this.getPlayersFromResponse(xhr.responseText);

            console.log("All players loaded. Count: " + playaz.length);

            this.setState({
                players: playaz,
                loading: false,
            });
        });

        this.setState({
            loading: true
        });

        xhr.open('GET', `${apiEndpoint}`);
        xhr.setRequestHeader(this.apimh, this.apimk);
        xhr.send();
    }
    
    getInteractions(player1: string, player2: string) {
        const apiEndpoint = "https://goboomboom.azure-api.net/v1/interactions";
        
        window.history.pushState({}, "", `/?p1=${player1}&p2=${player2}`);

        var xhr = new XMLHttpRequest()
        xhr.addEventListener('load', () => {
            var games = this.getInteractionsFromResponse(xhr.responseText).sort(
                    function(a: Game, b: Game) {
                    if (a.date >= b.date) {return -1;}
                    else {return 1;}
                });

            var p1 = this.state.players.filter(p => p.id === this.state.playerId1)[0];
            var p2 = this.state.players.filter(p => p.id === this.state.playerId2)[0];
            var summary = new Summary(p1, p2, games);

            this.setState({
                games,
                interactions: games.flatMap(g => g.interactions.sort(function(a: Interaction, b: Interaction) {
                    if (a.period > b.period) {return 1;}
                    else if (a.period < b.period) {return -1;}
                    else {
                        if (a.periodTimeSeconds > b.periodTimeSeconds) {return 1;}
                        else {return -1;}
                    }
                })),
                summary: summary,
                playerId1: player1,
                playerId2: player2,
                loading: false,
            });
        });

        this.setState({
            hasSearched: true,
            loading: true
        });
        xhr.open('GET', `${apiEndpoint}/${player1},${player2}`);
        xhr.setRequestHeader(this.apimh, this.apimk);
        xhr.send();
    }

    private getInteractionsFromResponse(response: string) {
        var gameInteractionsResponse = JSON.parse(response);

        var games: Game[] = [];
        for (let key in gameInteractionsResponse) {
            let gameResponse = gameInteractionsResponse[key];

            let interactions: Interaction[] = [];
            if (gameResponse.gameEventInteractions) {
                for (let iKey in gameResponse.gameEventInteractions) {
                    const iaResponse = gameResponse.gameEventInteractions[iKey];
                    const interaction = new Interaction(iaResponse);
                    interactions.push(interaction);
                }
            }

            let game: Game = {
                id: gameResponse.gamePk,
                type: gameResponse.gameType,
                date: new Date(gameResponse.gameDate),
                venue: gameResponse.venueName,
                teammates: gameResponse.teammates,
                player1_Id: gameResponse.p1Id,
                player1_Name: gameResponse.p1Name,
                player1_Team: gameResponse.p1TeamName,
                player1_TeamScore: gameResponse.p1TeamScore,
                player1_HomeAway: gameResponse.p1HomeAway,
                player1_Result: gameResponse.p1Result,
                player1_TimeOnIce: gameResponse.p1Toi,
                player1_Goals: gameResponse.p1Goals,
                player1_Assists: gameResponse.p1Assists,
                player1_Shots: gameResponse.p1Shots,
                player1_FaceoffWins: gameResponse.p1FaceOffWins,
                player1_FaceoffsTaken: gameResponse.p1FaceoffTaken,
                player1_PlusMinus: gameResponse.p1PlusMinus,
                player1_ShotsAgainst: gameResponse.p1ShotsAgainst,
                player1_Saves: gameResponse.p1Saves,
                player1_Decision: gameResponse.p1Decision,
                player1_SavePercentage: gameResponse.p1SavePercentage,
                player1_StarRating: gameResponse.p1StarRating,

                player2_Id: gameResponse.p2Id,
                player2_Name: gameResponse.p2Name,
                player2_Team: gameResponse.p2TeamName,
                player2_TeamScore: gameResponse.p2TeamScore,
                player2_HomeAway: gameResponse.p2HomeAway,
                player2_Result: gameResponse.p2Result,
                player2_TimeOnIce: gameResponse.p2Toi,
                player2_Goals: gameResponse.p2Goals,
                player2_Assists: gameResponse.p2Assists,
                player2_Shots: gameResponse.p2Shots,
                player2_FaceoffWins: gameResponse.p2FaceOffWins,
                player2_FaceoffsTaken: gameResponse.p2FaceoffTaken,
                player2_PlusMinus: gameResponse.p2PlusMinus,
                player2_ShotsAgainst: gameResponse.p2ShotsAgainst,
                player2_Saves: gameResponse.p2Saves,
                player2_Decision: gameResponse.p2Decision,
                player2_SavePercentage: gameResponse.p2SavePercentage,
                player2_StarRating: gameResponse.p2StarRating,

                interactions,
            };
            
            games.push(game);
        }
        return games;
    }

    private getPlayersFromResponse(response: string) {
        var json = JSON.parse(response);

        var players: Player[] = [];
        json.forEach((item: any) => {
            var p = new Player();
            p.name = item[0];
            p.id = item[1];
            p.birthCountry = item[2];
            p.birthDate = item[3];
            p.positionCode = item[4];
            players.push(p);
        });

        players = players.sort((player1, player2): number => {
            if (player1.name < player2.name) return -1;
            if (player1.name > player2.name) return 1;
            return 0;
        });

        return players;
    }

    public escapeRegexCharacters(str: string) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    public render(): React.ReactNode {

        // Teach Autosuggest how to calculate suggestions for any given input value.
        const getSuggestions = (value : string) => {
            
            // Get player names that contain the substring 'value'
            const escapedValue = value.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

            if (escapedValue === '') {
              return [];
            }
          
            const regex = new RegExp('\\b' + escapedValue, 'i');
            return this.state.players.filter(person => regex.test(person.name));
        };


        
        // When suggestion is clicked, Autosuggest needs to populate the input
        // based on the clicked suggestion. Teach Autosuggest how to calculate the
        // input value for every given suggestion.
        const getSuggestionValue = (suggestion: Player, isPlayer1: boolean) =>
        {
            if (isPlayer1) {
                this.setState({playerId1: suggestion.id});
            } else {
                this.setState({playerId2: suggestion.id});
            }
            
            return suggestion.name;
        };

        // Use your imagination to render suggestions.
        const renderSuggestion = (suggestion: Player) => (
            <div >
                {suggestion.name}
                <div className="autosuggest-subtext">
                    {`(${suggestion.birthCountry}, DOB: ${suggestion.birthDate})`}
                 </div>
            </div>
            
        );

        const onChange = (event:any, { newValue }:any, isPlayer1: boolean) => {
            if (isPlayer1) {
                this.setState({playerName1: newValue});
            } else {
                this.setState({playerName2: newValue});
            }
          };

        // Autosuggest will call this function every time you need to update suggestions.
        // You already implemented this logic above, so just use it.
        const onSuggestionsFetchRequested = ({ value }:any) => {
            this.setState({suggestions: getSuggestions(value)});
        };

        // Autosuggest will call this function every time you need to clear suggestions.
        const onSuggestionsClearRequested = () => {
            this.setState({
                suggestions: [] 
            });
        };

        var { playerName1, playerName2, suggestions} = this.state;

        // Autosuggest will pass through all these props to the input.
        var inputProps1 = {
            placeholder: 'Type player 1 name',
            value: playerName1,
            onChange: (a: any, b: any) => { onChange(a, b, true) }
        };
        var inputProps2 = {
            placeholder: 'Type player 2 name',
            value: playerName2,
            onChange: (a: any, b: any) => { onChange(a, b, false) }
        };
            
        return (
            <div className="App">
                <div>
                {
                    this.state.players && this.state.players.length > 0 &&
                    <div className="player-selector-section">
                        <div className="player-selector-container-items">
                            <div className="player-selector-1">

                            <AutoSuggest
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                                    getSuggestionValue={(p: Player) => getSuggestionValue(p, true)}
                                    renderSuggestion={renderSuggestion}
                                    inputProps={inputProps1}
                                />

                            </div>
                            <div className="player-selector-2-pad">and</div>
                            <div className="player-selector-2">

                                <AutoSuggest
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                                    getSuggestionValue={(a: any) => getSuggestionValue(a, false)}
                                    renderSuggestion={renderSuggestion}
                                    inputProps={inputProps2}
                                />

                            </div>
                        </div>
                        <div className="player-selector-button-container">
                            <Button className="player-selector-button" variant="info" 
                                disabled={!this.state.playerId1 || !this.state.playerId2}
                                onClick={() => this.getInteractions(this.state.playerId1, this.state.playerId2)}>Compare
                            </Button>
                        </div>
                    </div>
                }
                {
                    this.state.hasSearched && this.state.loading &&
                    <div>Loading...</div>
                }
                {
                    this.state.hasSearched && !this.state.loading && (!this.state.games || !this.state.games.length) &&
                    <div>No interactions found. Please choose a different player combo.</div>
                }
                {
                    this.state.hasSearched && !this.state.loading && !!this.state.games.length &&
                    (
                        <div>
                            <div className="summary-container">
                                <div style={{backgroundImage: `https://nhl.bamcontent.com/images/actionshots/8471214.jpg`, height: "20px", width: "33%", display: "table-cell"}}>  
                                    <p><a href={`https://www.nhl.com/player/${this.state.games[0].player1_Name.replace(" ", "-").toLowerCase()}-${this.state.playerId1}`}
                                    target="_blank" rel="noopener noreferrer">{this.state.games[0].player1_Name}</a></p>
                                    <img className="image-headshot" src={`https://cms.nhl.bamgrid.com/images/headshots/current/168x168/${this.state.games[0].player1_Id}.jpg`} />
                                </div>
                                <div className="summary-section">
                                    {/* <p className="section-label">Summary</p> */}
                                    <br/>
                                    <PwPSummaryComponent PwPSummary={this.state.summary.PwPSummary} />
                                    <PvPSummaryComponent PvPSummary={this.state.summary.PvPSummary} />
                                    <GwPSummaryComponent GwPSummary={this.state.summary.GwPSummary} />
                                    <GvPSummaryComponent GvPSummary={this.state.summary.GvPSummary} />
                                    <GvGSummaryComponent GvGSummary={this.state.summary.GvGSummary} />
                                </div>
                                <div className="summary-section">
                                <p><a href={`https://www.nhl.com/player/${this.state.games[0].player2_Name.replace(" ", "-").toLowerCase()}-${this.state.playerId2}`}
                                      target="_blank" rel="noopener noreferrer">{this.state.games[0].player2_Name}</a></p>
                                    <img className="image-headshot" src={`https://cms.nhl.bamgrid.com/images/headshots/current/168x168/${this.state.games[0].player2_Id}.jpg`} />
                                </div>
                            </div>
                            <div className="interactions-container">
                                {
                                    !this.state.games && 
                                    <div>No interactions found.</div>
                                }
                                {
                                    !!this.state.games.length && 
                                    <div>
                                        <div className="section-label">Interactions</div>
                                        <p className="explanation-text">Game events are only available since 2010</p>
                                        <div>{
                                            this.state.games.map((game, i) => {
                                                return this.renderGame(game);
                                            })
                                        }</div>
                                    </div>
                                }
                            </div>

                        </div>
                    )
                }
                </div>

                {/* <div className="feedback">
                    <div className="email">
                        <a className="emailLink" href = "mailto:team@seahac.com?subject=NHL Player Interaction Viewer">Contact</a>
                    </div>
                    <div className="twitterdm">
                        <a href="https://twitter.com/messages/compose?recipient_id=90535324" className="twitter-dm-button" data-screen-name="@jordanfitzgibbo"></a>
                    </div>
                </div> */}

            </div>
        );
    }

    private renderGame(game: Game): React.ReactNode {
        return (
        <div className={game.interactions && game.interactions.length > 0 ? "game game-with-interactions" : "game game-without-interactions"}>
        <div><a href={`https://www.nhl.com/gamecenter/${game.id}`} target="_blank" rel="noopener noreferrer" >{moment(game.date).tz("America/Toronto").format("MM/DD/YYYY")}: {this.getDisplayInteractionType(game.type)} - {game.venue}</a></div>
        {
            game.interactions.map((interaction, i) => {
                return this.renderInteraction(interaction);
            })
        }</div>
        );
    };

    private renderInteraction(interaction: Interaction): React.ReactNode {
        return (
            <div className="interaction">
                <div>P{interaction.period} {interaction.periodTime}: {this.getDisplayInteractionType(interaction.type)} - {interaction.description}</div>
            </div>
        );
    }

    private getDisplayInteractionType(interactionType: string) {
        switch(interactionType) {
            case "R":
                return "Regular Season Game";
            case "P":
                return "Playoff Game";
            case "PR":
                return "Preseason Game";
            case "A":
                return "All-Star Game";
            default:
                return interactionType;
        }
    } 
}