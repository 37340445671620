import { Game } from "./Game";
import { PlayerSummary } from "./PlayerSummary"
// import { GoalieSummary } from "./GoalieSummary"
import { Player } from "./Player"

// Goalie vs Player Summary model
export class GvPSummary {

    Player1: Player;
    Player2: Player;

    PS1: PlayerSummary = new PlayerSummary();
    PS2: PlayerSummary = new PlayerSummary();

    Games: Game[] = [];

    constructor(player1: Player, player2: Player, games: Game[]) {

        this.Player1 = player1;
        this.Player2 = player2;

        if (games === null || games.length === 0) {
            return;
        }

        // Regular season and playoff games
        var realGames = games.filter(g => g.type === "R" || g.type === "P").filter(g => !g.teammates);
        this.Games = realGames;
        var interactions = realGames.flatMap(g => g.interactions);

        var ps1: PlayerSummary = new PlayerSummary();
        ps1.GamesWon = realGames.filter(g => g.player1_Result === "W").length;
        ps1.GamesLost = realGames.filter(g => g.player1_Result === "L").length;
        ps1.GamesTied = realGames.filter(g => g.player1_Result === "T").length;
        
        ps1.Goals = interactions.filter(i => i.type === "Goal" && i.players[0].Type === "Scorer" && i.players[1].Type === "Goalie").length;
        ps1.Assists = interactions.filter(i => i.type === "Goal" && i.players[0].Type === "Assist" && i.players[1].Type === "Goalie").length;
        ps1.Penalties = interactions.filter(i => i.type === "Penalty" && i.players[0].Type === "PenaltyOn").length;
        ps1.PIMS = interactions.filter(i => i.type === "Penalty" && i.players[0].Type === "PenaltyOn").reduce((sum, current) => sum + current.penaltyMinutes, 0);
        ps1.Fights = interactions.filter(i => i.type === "Penalty" && i.players[0].Type === "PenaltyOn" && i.secondaryType.toLocaleLowerCase().includes("fight")).length;
        ps1.Shots = interactions.filter(i => i.type === "Shot" && i.players[0].Type === "Shooter").length; // This is shots not resulting in a goal
        ps1.Saves = interactions.filter(i => i.type === "Shot" && i.players[0].Type === "Goalie").length;

        var ps2: PlayerSummary = new PlayerSummary();
        ps2.GamesWon = realGames.filter(g => g.player2_Result === "W").length;
        ps2.GamesLost = realGames.filter(g => g.player2_Result === "L").length;
        ps2.GamesTied = realGames.filter(g => g.player2_Result === "T").length;
        
        ps2.Goals = interactions.filter(i => i.type === "Goal" && i.players[1].Type === "Scorer" && i.players[0].Type === "Goalie").length; // TODO: also verify that g1 was the goalie
        ps2.Assists = interactions.filter(i => i.type === "Goal" && i.players[1].Type === "Assist" && i.players[0].Type === "Goalie").length;
        ps2.Penalties = interactions.filter(i => i.type === "Penalty" && i.players[1].Type === "PenaltyOn").length;
        ps2.PIMS = interactions.filter(i => i.type === "Penalty" && i.players[1].Type === "PenaltyOn").reduce((sum, current) => sum + current.penaltyMinutes, 0);
        ps2.Fights = interactions.filter(i => i.type === "Penalty" && i.players[1].Type === "PenaltyOn" && i.secondaryType.toLocaleLowerCase().includes("fight")).length;
        ps2.Shots = interactions.filter(i => i.type === "Shot" && i.players[1].Type === "Shooter").length; // This is shots not resulting in a goal
        ps2.Saves = interactions.filter(i => i.type === "Shot" && i.players[1].Type === "Goalie").length;

        this.PS1 = ps1;
        this.PS2 = ps2;
    }

}