import { Game } from "./Game";
import { Player } from "./Player"
import { PvPSummary } from "./PvPSummary"
import { PwPSummary } from "./PwPSummary"
import { GvPSummary } from "./GvPSummary"
import { GwPSummary } from "./GwPSummary"
import { GvGSummary } from "./GvGSummary"

export class Summary {

    P1: Player;
    P2: Player;
    PvPSummary: PvPSummary;
    PwPSummary: PwPSummary;
    GvPSummary: GvPSummary;
    GwPSummary: GwPSummary;
    GvGSummary: GvGSummary;

    constructor(p1: Player, p2: Player, games: Game[])
    {
        this.P1 = p1;
        this.P2 = p2;
        this.PvPSummary = new PvPSummary(p1, p2, games);
        this.PwPSummary = new PwPSummary(p1, p2, games);
        this.GvPSummary = new GvPSummary(p1, p2, games);
        this.GwPSummary = new GwPSummary(p1, p2, games);
        this.GvGSummary = new GvGSummary(p1, p2, games);
    }
}