import { PwPSummary } from "../models/PwPSummary"

import React from 'react';
import 'react-select2-wrapper/css/select2.css';
import './Home.css';

type MyProps = {
    PwPSummary: PwPSummary;
};

// Renders the Player with Player Summary model
export class PwPSummaryComponent extends React.Component<MyProps> {

    // Decide if this component should be displayed
    showComponent(): boolean {
        
        if (this.props.PwPSummary.Games === null || this.props.PwPSummary.Games.length === 0) {
            return false;
        }

        if (this.props.PwPSummary.Player1.isGoalie() || this.props.PwPSummary.Player2.isGoalie()) {
            return false;
        }

        return true;
    }

    render(): React.ReactNode {

        if (!this.showComponent())
        {
             return (<div/>);    
        }

        return (
            <div>
                <div className="section-label2">As Teammates</div>
                <div className="section-label3"> 
                    <div>Wins-Losses-Ties: {this.props.PwPSummary.PS2.GetRecord()}</div>
                    <div>{this.props.PwPSummary.PS1.GoalsAssistedByTeammate} &larr; Goals that other Teammate assisted on &rarr; {this.props.PwPSummary.PS2.GoalsAssistedByTeammate}</div>
                    <div>{this.props.PwPSummary.PS1.GoalsAssistedOn} &larr; Assists on Teammate's goal &rarr; {this.props.PwPSummary.PS2.GoalsAssistedOn}</div>
                    <div>{this.props.PwPSummary.PS1.BothAssistedOn} &larr; Goals that both Assisted on &rarr; {this.props.PwPSummary.PS2.BothAssistedOn}</div>
                    <br/>
                </div>
            </div>
        );
    }
}