import { GwPSummary } from "../models/GwPSummary"

import React from 'react';
import 'react-select2-wrapper/css/select2.css';
import './Home.css';

type MyProps = {
    GwPSummary: GwPSummary;
};

// Renders the Goalie with Player Summary model
export class GwPSummaryComponent extends React.Component<MyProps> {

    // Decide if this component should be displayed
    showComponent(): boolean {

        if (this.props.GwPSummary.Games === null || this.props.GwPSummary.Games.length === 0) {
            return false;
        }

        if ((this.props.GwPSummary.Player1.isSkater() && this.props.GwPSummary.Player2.isGoalie())
            || (this.props.GwPSummary.Player1.isGoalie() && this.props.GwPSummary.Player2.isSkater()))
        {
            return true;
        }

        return false;
    }

    render(): React.ReactNode {
        
        if (!this.showComponent())
        {
             return (<div/>);    
        }

        // We could also show how many shots the player blocked for the goalie but need to combine it with on-ice data

        return (
            <div>
                <div className="section-label2">As Teammates</div>
                <div className="section-label3">
                    <div>Wins-Losses-Ties: {this.props.GwPSummary.PS2.GetRecord()}</div>
                    <div>Combined Points: {this.props.GwPSummary.CombinedPoints}</div>
                    <br/>
                </div>
            </div>
        );
    }
}