import { IceCoordinates } from "./IceCoordinates";
import { triggerAsyncId } from "async_hooks";

export class InteractionPlayer{

    Index: number; // Player 1 or 2
    Id: string;
    Name: string;
    Position: string;
    Team: string;
    Type: string;

    // player is the player info returned in the interaction object by the api
    constructor(index: number, id: string, name: string, position: string, team: string, type: string) {
        this.Index = index;
        this.Id = id;
        this.Name = name;
        this.Position = position;
        this.Team = team;
        this.Type = type;
    }
}

export class Interaction {
    gameId: string;
    eventId: string;
    type: string;
    secondaryType: string;
    team: string;
    description: string;
    iceCoordinates: IceCoordinates;
    penaltyMinutes: number;
    period: string;
    periodTime: string;
    periodTimeSeconds: number;
    teammates: boolean;
    players: InteractionPlayer[];

    // interaction is the object returned by the api
    constructor(interaction: any) {
        
        this.gameId = interaction.gamePk;
        this.eventId = interaction.eventId;
        this.type = interaction.eventName;
        this.secondaryType = interaction.secondaryType;
        this.team= interaction.teamOfEvent;
        this.description= interaction.description;
        this.iceCoordinates = {
            x: interaction.xCoordinates,
            y: interaction.yCoordinates,
        };
        this.penaltyMinutes = interaction.penaltyMinutes;
        this.period = interaction.period;
        this.periodTime = interaction.periodTime;
        this.periodTimeSeconds= interaction.periodTimeSeconds;
        this.teammates= interaction.teammates;
        this.players = [
            new InteractionPlayer(0, interaction.p1Id, interaction.p1Name, interaction.p1Position, interaction.p1TeamName, interaction.p1Type),
            new InteractionPlayer(1, interaction.p2Id, interaction.p2Name, interaction.p2Position, interaction.p2TeamName, interaction.p2Type),
            ];
    }

}