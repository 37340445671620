export class Player {
    id: string = "";
    name: string = "";
    birthCountry: string = "";
    birthDate: string = "";
    positionCode: string = "";

    constructor()
    {
    }

    isSkater(): boolean
    {
        if (this.positionCode === "C" || this.positionCode === "L" || this.positionCode === "R" || this.positionCode === "D")
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    isGoalie(): boolean
    {
        return this.positionCode === "G";
    }
}
